import React from "react";
import styled from "styled-components";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #dde1d4;
  z-index: 10000;
  cursor: pointer;

  width: ${(props) => props.containerWidth}vw;
  opacity: ${(props) => props.splashOpacity};

  transition: 300ms width ease, 300ms opacity ease;

  @media (max-width: 768px) {
    width: 100vw;
    height: ${(props) => props.containerWidth}vh;

    transition: 300ms height ease, 300ms opacity ease;
  }

  & .text-container {
    display: grid;
    grid-template-rows: 100px auto 100px;
    align-items: center;

    width: 100%;
    height: 100%;

    text-align: center;

    opacity: ${(props) => props.textOpacity};
    transition: 300ms opacity ease;
  }

  & h1 {
    font-size: 48px;
    line-height: 55px;

    margin: 0;

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 23px;
    }
  }

  & p {
    font-size: 24px;
    line-height: 30px;

    margin: 0;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const Splash = ({
  textOpacity,
  containerWidth,
  triggerHideSplash,
  splashOpacity,
}) => {
  return (
    <SplashContainer
      textOpacity={textOpacity}
      containerWidth={containerWidth}
      splashOpacity={splashOpacity}
      onClick={() => triggerHideSplash()}
    >
      <div className="text-container">
        <p className="spacer"></p>

        <h1>Jamie Whelan Studio</h1>

        <p className="location">Dublin | London</p>
      </div>
    </SplashContainer>
  );
};
