import React, { useContext } from "react";

// Context
import { AboutTitle } from "../context/about-title";

export const AboutSectionTitle = () => {
  const [aboutTitle, setAboutTitle] = useContext(AboutTitle);

  return <h2>{aboutTitle}</h2>;
};
