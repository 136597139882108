import React, { useEffect, useState } from "react";

// Styles
import "../styles/plyr.css";

export const VideoWithControls = ({ content }) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-with-controls${content.id}`, {
          autoplay: false,
          muted: false,
          loop: { active: false },
          invertTime: false,
          controls: ["current-time", "play", "progress", "fullscreen"],
          hideControls: true,
          youtube: {
            modestbranding: 1,
            playsinline: 1,
            autoplay: 0,
            showinfo: 0,
            controls: 0,
          },
          clickToPlay: true,
        })
      );
    }
  }, []);

  if (content.primary.video.embed_url !== null) {
    return (
      <div
        className="plyr__video-embed"
        id={`video-with-controls${content.id}`}
        dangerouslySetInnerHTML={{
          __html: content.primary.video.html,
        }}
      />
    );
  } else {
    return null;
  }
};
