import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Context
// import { StoreProvider } from "./src/components/context/store-context";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import About from "./src/pages/about";
import Index from "./src/pages/index";

// Templates
import Project from "./src/templates/project";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          about: About,
          homepage: Index,
          project: Project,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
