import React, { useState, useEffect, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { PrismicRichText } from "@prismicio/react";
import { useLockBodyScroll, useToggle, useScrollbarWidth } from "react-use";

// Components
import { AboutSectionTitle } from "../about/about-section-title";
import NewsFilter from "../news/news-filter";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 200;

  & .header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .background-bar {
      width: 50vw;
      padding: 0 30px;

      &.left {
        // background-color: #fff;
      }

      &.right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        background-color: ${(props) => props.backgroundColor};
        width: calc(50vw - ${(props) => props.sbw}px);
      }

      & button {
        &.menu-toggle {
          margin: 0 0 0 auto;
          display: block;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            font-family: "Selecta Bold", sans-serif;
          }
        }
      }
    }

    & h1,
    & h2,
    & .menu-toggle {
      font-size: 24px;
      line-height: 70px;

      @media (max-width: 1000px) {
        font-size: 17px;
        line-height: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    & .header-bar {
      & .background-bar {
        padding: 0 15px;

        background-color: ${(props) => props.backgroundColor};
      }
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 50vw;
  height: ${(props) => props.height}px;

  transform: translateX(${(props) => props.translateX});
  transition: 500ms transform ease;

  padding: 30px;

  background-color: ${(props) => props.backgroundColor};

  z-index: 210;

  font-size: 24px;
  line-height: 28px;

  @media (max-width: 1000px) {
    font-size: 17px;
    line-height: 125%;
  }

  & .top-section,
  & .bottom-section {
    flex: 100%;
  }

  & .top-level-navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & button {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }
  }

  & .bottom-section {
    margin: auto 0 0 0;

    & p {
      &:last-of-type {
        margin: 1em 0 0 0;
      }
    }
  }

  & ol {
    margin: 30px 0 0 0;
  }

  & a {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        font-family: "Selecta Bold", sans-serif;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100vw;
    padding: 15px;

    & ol {
      line-height: 150%;
    }
  }
`;

export const Header = ({ location, pageContext }) => {
  const [isMenuOpen, setIsMenuOpen] = useToggle(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  useLockBodyScroll(isMenuOpen);

  const height = use100vh();
  const sbw = useScrollbarWidth();
  const ref = useRef(null);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          menu {
            item {
              document {
                ... on PrismicAbout {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicProjects {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicNews {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          contact_text {
            richText
          }
        }
      }
    }
  `);

  const menuItems = data.prismicMenu.data.menu
    .filter((item) => item.item.document !== null)
    .map((item, index) => (
      <li key={`single_menu_item_${index}`}>
        <Link to={item.item.document.url}>
          {item.item.document.data.title.text}
        </Link>
      </li>
    ));

  return (
    <>
      <HeaderWrapper
        backgroundColor={pageContext.pageColor}
        sbw={isMenuOpen ? 0 : sbw}
      >
        <div className="header-bar">
          <div className="background-bar left">
            <h1>{pageContext.pageTitle}</h1>
          </div>

          <div className="background-bar right">
            {pageContext.pageType === `news` && <NewsFilter />}
            {pageContext.pageType === `about` && <AboutSectionTitle />}

            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="menu-toggle"
            >
              {isDesktop || location === `/` ? `Jamie Whelan Studio` : `JWS`}
            </button>
          </div>
        </div>

        <Menu
          backgroundColor={pageContext.pageColor}
          translateX={isMenuOpen ? `0` : `100%`}
          height={height}
          ref={ref}
          onMouseLeave={() => setIsMenuOpen(false)}
        >
          <div className="top-section">
            <div className="top-level-navigation">
              <Link to={`/`} onClick={() => setIsMenuOpen(false)}>
                Jamie Whelan Studio
              </Link>

              <button onClick={() => setIsMenuOpen(false)}>✕</button>
            </div>

            <ol>{menuItems}</ol>
          </div>

          <div className="bottom-section">
            <PrismicRichText
              field={data.prismicMenu.data.contact_text.richText}
            />
          </div>
        </Menu>
      </HeaderWrapper>
    </>
  );
};
