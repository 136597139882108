import React, { useRef, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useMouse, useScrollbarWidth } from "react-use";
import { isMobile } from "react-device-detect";

// Containers
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const Page = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  & .column {
    grid-column: span 1;
    padding: 70px 0 0 0;

    &.left {
      width: 50vw;
    }

    &.right {
      background-color: #dde1d4;
      width: calc(50vw - ${(props) => props.sbw}px);
    }

    & .single-project-container {
      display: block;
      width: 100%;
      position: relative;

      overflow: hidden;

      margin: 2em 0;
      padding: 0 20px;

      /* padding: 0 0 30px 0; */
      cursor: none;

      & a {
        cursor: none;
      }

      /* &.padded {
        padding: 0 30px 30px 30px;
      }

      &.image-portrait {
        padding: 90px 120px;
      } */
    }
  }

  @media (max-width: 767px) {
    display: block;
    grid-template-columns: unset;

    & .column {
      grid-column: unset;
      width: 100%;

      &.left {
        padding: 40px 0 0 0;
      }

      &.right {
        padding: 15px 0;
      }

      & .single-project-container {
        padding: 0 15px;
      }

      /* & .single-project-container {
        &.padded {
          &.image-landscape {
            margin: 15px 0;
            padding: 0 15px;
          }

          &.image-portrait {
            margin: 30px 0;
            padding: 0 50px;
          }
        }

        &.image-portrait {
          margin: 30px 0;
          padding: 0 50px;
          // padding: 30px 50px;
        }
      } */
    }
  }
`;

const DesktopCaption = styled.div`
  z-index: 100;

  cursor: none;
  pointer-events: none;

  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: none;

  & p {
    position: absolute;
    margin: 0;

    cursor: none;

    font-size: 24px;
    line-height: 30px;

    transition: 50ms top ease, 50ms left ease;

    & span {
      color: #dde1d4;
    }

    @media (max-width: 1000px) {
      font-size: 17px;
      line-height: 125%;
    }
  }
`;

export const HomepageDesktop = ({ data }) => {
  const sbw = useScrollbarWidth();

  const SingleProject = ({ project, index }) => {
    const activeProjectRef = useRef(null);
    const activeProjectRefMouseData = useMouse(activeProjectRef);

    const [isTitleVisible, setIsTitleVisible] = useState(false);

    return (
      <div
        className={`single-project-container ${
          project.full_width ? `full-width` : `padded`
        } image-${ImageOrientation(project.image)}`}
        ref={activeProjectRef}
        onMouseOver={() => {
          if (!isMobile) {
            setIsTitleVisible(true);
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setIsTitleVisible(false);
          }
        }}
      >
        <Link
          to={
            project.project.document.type === `project`
              ? project.project.url
              : `/news/`
          }
          state={{
            id: project.project.document.id,
          }}
        >
          <AspectRatioImageContainer image={project.image}>
            <img
              srcSet={project.image.fluid.srcSet}
              src={project.image.fluid.src}
              alt={project.image.alt}
              loading={index <= 2 ? `eager` : `lazy`}
            />
          </AspectRatioImageContainer>
        </Link>

        {isTitleVisible && !isMobile && (
          <DesktopCaption>
            <p
              style={{
                top: `${activeProjectRefMouseData.elY}px`,
                left: `${activeProjectRefMouseData.elX}px`,
              }}
            >
              <span className="arrow">↖</span>{" "}
              {project.project.document.data.title.text}
            </p>
          </DesktopCaption>
        )}
      </div>
    );
  };

  const leftColumnProjects = data.prismicHomepage.data.left_column_projects
    .filter((project) => project.project.document !== null)
    .filter((project) => project.image.fluid !== null)
    .map((content, index) => (
      <SingleProject
        project={content}
        key={`single_project_left_column_${index}_${content.project.document.id}`}
        index={index}
      />
    ));

  const rightColumnProjects = data.prismicHomepage.data.right_column_projects
    .filter((project) => project.project.document !== null)
    .filter((project) => project.image.fluid !== null)
    .map((content, index) => (
      <SingleProject
        project={content}
        key={`single_project_right_column_${index}_${content.project.document.id}`}
        index={index}
      />
    ));

  return (
    <Page sbw={sbw}>
      <div className="column left">{leftColumnProjects}</div>
      <div className="column right">{rightColumnProjects}</div>
    </Page>
  );
};
