import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html,
html.wf-loading {
  body {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

body {
  font-family: "Selecta Regular", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 24px;
  line-height: 30px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  @media(max-width: 1000px){
    font-size: 17px;
    line-height: 125%;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Selecta Regular", sans-serif;
  font-weight: normal;

  font-size: 24px;
  line-height: 30px;

  @media(max-width: 1000px){
    font-size: 17px;
    line-height: 125%;
  }

  margin: 0;
}

.caption{
  font-size: 18px;
  line-height: 22px;

  @media(max-width: 1000px){
    font-size: 14px;
    line-height: 125%;
  }

  // @media(max-width: 767px){
  //   font-size: 13px;
  //   line-height: 18px;
  // }
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button {
    background-color: transparent;
    appearance: none;
    padding: 0;
    margin: 0;
    border: 0;

    color: #000;
    font-weight: normal;
    cursor: pointer;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;

  & a {
    &:hover {
      @media (hover: hover) and (pointer: fine) {
        font-family: "Selecta Bold", sans-serif;
      }
    }
  }
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}
`;

export default GlobalStyle;
