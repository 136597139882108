import React, { useState, useContext, useEffect, useRef } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { PrismicRichText } from "@prismicio/react";
import { useIntersection } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageContext } from "../components/context/page-context";
import { AboutTitle } from "../components/context/about-title";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  // height: ${(props) => props.height}px;
  // scroll-snap-type: y mandatory;
  // overflow: auto;

  & .next-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50vh - 35px);

    cursor: url(/icons/cursor-next@1x.png) 0 0, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/cursor-next@1x.png) 1x,
          url(/icons/cursor-next@2x.png) 2x
        )
        0 0,
      pointer !important;

    display: none;

    @media (hover: hover) and (pointer: fine) {
      display: block;
    }
  }

  & .prev-button {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    height: calc(50vh - 35px);

    cursor: url(/icons/cursor-prev@1x.png) 0 0, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/cursor-prev@1x.png) 1x,
          url(/icons/cursor-prev@2x.png) 2x
        )
        0 0,
      pointer !important;

    display: none;

    @media (hover: hover) and (pointer: fine) {
      display: block;
    }
  }

  & .panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // scroll-snap-align: start;

    min-height: ${(props) => props.height}px;

    & .column {
      grid-column: span 1;
      padding: 110px 30px;

      width: 50vw;

      &.right {
        background-color: #f8efd1;

        & .text-container {
          & p {
            &:first-of-type {
              margin-top: 0;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.last-section {
      & .column {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        padding: 110px 30px 30px 30px;

        &.left {
          align-content: flex-end;

          & .caption {
            font-feature-settings: "tnum";
          }
        }

        &.right {
          align-content: space-between;

          & .text-container,
          & .credits {
            flex: 100%;
          }
        }
      }

      & p {
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    background-color: #f8efd1;

    & .panel {
      display: block;
      grid-template-columns: unset;

      & .column {
        grid-column: unset;
        width: 100%;

        &.left {
          padding: 50px 15px 0 15px;
        }
        &.right {
          padding: 15px;
        }
      }

      &.panel-1 {
        & .column {
          &.left {
            padding: 50px 15px 0 15px;
          }
          &.right {
            padding: 15px;
          }
        }
      }

      &.last-section {
        & .column {
          &.left {
            display: none;
          }

          &.right {
            min-height: ${(props) => props.height}px;
          }
        }
      }
    }
  }
`;

const TextWithImageSection = ({ data, index, setAboutTitle }) => {
  const panelOneRef = useRef(null);
  const panelOneIntersection = useIntersection(panelOneRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.25,
  });

  useEffect(() => {
    if (panelOneIntersection !== null) {
      if (panelOneIntersection.isIntersecting) {
        setAboutTitle(data.primary.section_title.text);
      }
    }
  }, [panelOneIntersection]);

  return (
    <div
      className={`panel panel-${index + 1} text-with-image-section`}
      ref={panelOneRef}
    >
      <div className="column left">
        <div className="image-container">
          <AspectRatioImageContainer image={data.primary.image}>
            <img
              srcSet={data.primary.image.fluid.srcSet}
              src={data.primary.image.fluid.src}
              alt={data.primary.image.alt}
            />
          </AspectRatioImageContainer>
        </div>
      </div>

      <div className="column right">
        <div className="text-container">
          <PrismicRichText field={data.primary.text.richText} />
        </div>
      </div>
    </div>
  );
};

const TextSection = ({ data, index, setAboutTitle, year, isLastSection }) => {
  const panelTwoRef = useRef(null);
  const panelTwoIntersection = useIntersection(panelTwoRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.25,
  });

  useEffect(() => {
    if (panelTwoIntersection !== null) {
      if (panelTwoIntersection.isIntersecting) {
        setAboutTitle(data.primary.section_title.text);
      }
    }
  }, [panelTwoIntersection]);

  return (
    <div
      className={`panel panel-${index + 1} text-section ${
        isLastSection ? `last-section` : ``
      }`}
      ref={panelTwoRef}
    >
      <div className="column left">
        {isLastSection && (
          <p className="caption">Copyright Jamie Whelan {year}</p>
        )}
      </div>

      <div className="column right">
        <div className="text-container">
          <PrismicRichText field={data.primary.text.richText} />
        </div>

        {isLastSection && (
          <div className="credits">
            <p className="caption">
              Design by Gareth Lindsay, Development by{" "}
              <a href="https://kieranstartup.co.uk" target="_blank">
                Kieran Startup
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const About = ({ data }) => {
  const [aboutTitle, setAboutTitle] = useContext(AboutTitle);
  const [pageContext, setPageContext] = useContext(PageContext);
  const height = use100vh();

  useEffect(() => {
    setPageContext({
      pageTitle: `About`,
      pageColor: `#F8EFD1`,
      pageType: `about`,
    });
  }, []);

  const [year, setYear] = useState(2022);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, []);

  const content = data.prismicAbout.data.body.map((content, index) => {
    if (content.slice_type === `text_with_image_section`) {
      return (
        <TextWithImageSection
          data={content}
          key={content.id}
          setAboutTitle={setAboutTitle}
          index={index}
        />
      );
    }

    if (content.slice_type === `text_section`) {
      return (
        <TextSection
          data={content}
          key={content.id}
          setAboutTitle={setAboutTitle}
          index={index}
          year={year}
          isLastSection={data.prismicAbout.data.body.length === index + 1}
        />
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={`About - Jamie Whelan Studio`}
        seoText={null}
        seoImage={null}
      />

      <Page height={height}>
        <button
          className="prev-button"
          onClick={() => {
            if (typeof window !== `undefined`) {
              window.scrollBy({
                top: -window.innerHeight,
                left: 0,
                behavior: "smooth",
              });
            }
          }}
        />
        <button
          className="next-button"
          onClick={() => {
            if (typeof window !== `undefined`) {
              window.scrollBy({
                top: window.innerHeight,
                left: 0,
                behavior: "smooth",
              });
            }
          }}
        />
        {content}
      </Page>
    </>
  );
};

export default withPrismicPreview(About);

export const query = graphql`
  {
    prismicAbout {
      url
      _previewable
      data {
        body {
          ... on PrismicAboutDataBodyTextSection {
            id
            slice_type
            primary {
              section_title {
                text
                richText
              }
              text {
                richText
              }
            }
          }
          ... on PrismicAboutDataBodyTextWithImageSection {
            id
            slice_type
            primary {
              section_title {
                text
                richText
              }
              text {
                richText
              }
              image {
                fluid {
                  srcSet
                  src
                  aspectRatio
                }
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
