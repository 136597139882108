import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const PageSeo = ({ seoTitle, seoText, seoImage }) => {
  const data = useStaticQuery(graphql`
    {
      prismicSeo {
        data {
          seo_title
          seo_description
          seo_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Helmet
      title={`${seoTitle !== null ? seoTitle : data.prismicSeo.data.seo_title}`}
      meta={[
        {
          name: "description",
          content: `${
            seoText !== null ? seoText : data.prismicSeo.data.seo_description
          }`,
        },
        {
          name: "og:image",
          content: `${
            seoImage !== null ? seoImage : data.prismicSeo.data.seo_image.url
          }`,
        },
        {
          name: "og:image:secure_url",
          content: `${
            seoImage !== null ? seoImage : data.prismicSeo.data.seo_image.url
          }`,
        },
        {
          name: "og:description",
          content: `${
            seoText !== null ? seoText : data.prismicSeo.data.seo_description
          }`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "og:title",
          content: `${
            seoTitle !== null ? seoTitle : data.prismicSeo.data.seo_title
          }`,
        },
        {
          name: "twitter:title",
          content: `${
            seoTitle !== null ? seoTitle : data.prismicSeo.data.seo_title
          }`,
        },
        {
          name: "twitter:description",
          content: `${
            seoText !== null ? seoText : data.prismicSeo.data.seo_description
          }`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `${
            seoImage !== null ? seoImage : data.prismicSeo.data.seo_image.url
          }`,
        },
      ]}
    />
  );
};
