import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageContext } from "../components/context/page-context";

// Components
import { HomepageDesktop } from "../components/homepage/homepage-desktop";
import { HomepageMobile } from "../components/homepage/homepage-mobile";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Index = ({ data }) => {
  const [pageContext, setPageContext] = useContext(PageContext);

  useEffect(() => {
    setPageContext({ pageTitle: ``, pageColor: `#DDE1D4`, pageType: `home` });
  }, []);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <PageSeo
        seoTitle={`Jamie Whelan Studio`}
        seoText={null}
        seoImage={null}
      />

      {isDesktop ? (
        <HomepageDesktop data={data} />
      ) : (
        <HomepageMobile data={data} />
      )}
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      _previewable
      data {
        left_column_projects {
          project {
            url
            document {
              ... on PrismicProject {
                id
                type
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicNewsArticle {
                id
                type
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          image {
            fluid {
              srcSet
              src
              aspectRatio
            }
            alt
            dimensions {
              height
              width
            }
          }
          full_width
        }
        right_column_projects {
          project {
            url
            document {
              ... on PrismicProject {
                id
                type
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicNewsArticle {
                id
                type
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          image {
            fluid {
              srcSet
              src
              aspectRatio
            }
            alt
            dimensions {
              height
              width
            }
          }
          full_width
        }
      }
    }
  }
`;
