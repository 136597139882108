exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "project": {
      return `/projects/${doc.uid}/`;
    }

    // case "collection": {
    //   // return `/collections/${doc.lang}/${doc.uid}/`;
    //   return `/${doc.lang}/collections/${doc.uid}/`;
    // }

    case "homepage": {
      return `/`;
    }

    default:
      return `/${doc.uid}/`;
  }
};
