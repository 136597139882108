import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import { Header } from "./header";

const Main = styled.main``;

export const App = ({
  location,
  children,
  pageContext,
  setHideSplash,
  setContentOpacity,
}) => {
  useEffect(() => {
    if (location.pathname !== `/`) {
      setHideSplash(true);
      setContentOpacity(1);
    }
  }, [location]);

  return (
    <>
      <Header location={location} pageContext={pageContext} />
      <Main>{children}</Main>
    </>
  );
};
