import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const NewsFilterContainer = styled.div`
  position: relative;

  &.open {
    height: ${(props) => props.height}px;
  }

  & p {
    margin: 0;

    line-height: 70px;
    font-feature-settings: "tnum";

    @media (max-width: 767px) {
      line-height: 40px;
    }
  }

  & ol {
    position: absolute;
    top: 20px;
    left: 0;

    & li {
      & button {
        font-feature-settings: "tnum";
      }
    }

    @media (max-width: 767px) {
      top: 10px;
    }
  }
`;

const NewsFilter = () => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);

  const data = useStaticQuery(graphql`
    {
      allPrismicNewsArticle(sort: { order: DESC, fields: data___date }) {
        nodes {
          id
          data {
            date(formatString: "YYYY")
          }
        }
      }
    }
  `);

  const years = Array.from(
    new Set(data.allPrismicNewsArticle.nodes.map((item) => item.data.date))
  ).map((year, index) => (
    <li key={`single_year_${index}_${year}`}>
      <button
        onClick={() => {
          setActiveFilter(year);
          setFiltersVisible(false);
          if (typeof window !== `undefined`) {
            document.getElementById(year).scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start",
            });
          }
        }}
      >
        {year}
      </button>
    </li>
  ));

  return (
    <NewsFilterContainer
      height={years.length * 30 + 40}
      onMouseEnter={() => setFiltersVisible(true)}
      onMouseLeave={() => setFiltersVisible(false)}
      className={filtersVisible ? `open` : `closed`}
    >
      {filtersVisible ? (
        <ol>{years}</ol>
      ) : (
        <p>{activeFilter !== null ? activeFilter : years[0]}</p>
      )}
    </NewsFilterContainer>
  );
};

export default NewsFilter;
