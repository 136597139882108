import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useMouse } from "react-use";

// Gallery
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  // height: 100%;

  /* margin: 0 0 2em 0; */
  margin: 2em 0;

  padding: 0 20px;

  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;

    &.image-landscape {
      object-fit: cover;
    }

    &.image-portrait {
      object-fit: contain;
    }
  }

  & .caption {
    display: none;
  }

  @media (max-width: 767px) {
    margin: 0 0 20px 0;

    & .caption {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      padding: 15px 0 0 0;

      /* &.project-padded {
        padding: 15px 15px 0 15px;
      } */

      & p {
        margin: 0;
        font-size: 17px;
        line-height: 125%;
      }
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;

  overflow: hidden;

  z-index: 100;

  cursor: none;

  &.previous {
    left: 0;

    cursor: url(/icons/arrow-prev-${(props) => props.color}@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-prev-${(props) => props.color}@1x.png) 1x,
          url(/icons/arrow-prev-${(props) => props.color}@2x.png) 2x
        )
        9 9,
      pointer !important;
  }

  &.next {
    right: 0;

    cursor: url(/icons/arrow-next-${(props) => props.color}@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-next-${(props) => props.color}@1x.png) 1x,
          url(/icons/arrow-next-${(props) => props.color}@2x.png) 2x
        )
        9 9,
      pointer !important;
  }

  & .counter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    position: absolute;
    width: 100px;

    margin: 0;

    font-size: 24px;
    line-height: 30px;

    & .arrow {
      color: ${(props) => props.color};
      font-size: 34px;

      margin: 0 10px;
    }
  }

  /* @media (hover: none) and (pointer: coarse) {
    display: none;
  } */
`;

export const Gallery = ({ color, galleryContent, type }) => {
  // Slideshow Reference
  const slideRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(null);

  // Mouse counter
  const refPrev = useRef(null);
  const prevMouseData = useMouse(refPrev);

  const refNext = useRef(null);
  const nextMouseData = useMouse(refNext);

  useEffect(() => {
    setTotalSlides(galleryContent.length);
  }, []);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <GalleryContainer>
      {galleryContent.length > 1 ? (
        <>
          <Arrow
            color={color}
            aria-label="Previous Slide"
            className={`previous`}
            ref={refPrev}
            onClick={() => {
              slideRef.current.goBack();
            }}
          >
            {!isMobile && (
              <p
                className="counter"
                style={{
                  top: `${prevMouseData.elY - 8}px`,
                  left: `${prevMouseData.elX - 10}px`,
                }}
              >
                <span className="prev-arrow arrow">←</span>
                <span>
                  {currentSlide + 1}/{totalSlides}
                </span>
              </p>
            )}
          </Arrow>
          <Fade
            ref={slideRef}
            arrows={false}
            transitionDuration={350}
            pauseOnHover={false}
            autoplay={false}
            infinite={true}
            canSwipe={galleryContent.length > 1 ? true : false}
            defaultIndex={0}
            onChange={(previous, next) => updateCurrentSlide(next)}
          >
            {galleryContent}
          </Fade>
          <Arrow
            color={color}
            aria-label="Next Slide"
            className={`next`}
            ref={refNext}
            onClick={() => {
              slideRef.current.goNext();
            }}
          >
            {!isMobile && (
              <p
                className="counter"
                style={{
                  top: `${nextMouseData.elY - 8}px`,
                  left: `${nextMouseData.elX - 10}px`,
                }}
              >
                <span>
                  {currentSlide + 1}/{totalSlides}
                </span>
                <span className="next-arrow arrow">→</span>
              </p>
            )}
          </Arrow>

          <div
            className={`caption ${type === `project` ? `project-padded` : ``}`}
          >
            <p>
              {currentSlide + 1}/{totalSlides}
            </p>
          </div>
        </>
      ) : (
        <>{galleryContent}</>
      )}
    </GalleryContainer>
  );
};
