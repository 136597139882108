import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { useScrollbarWidth, useMeasure } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageContext } from "../components/context/page-context";
import { PrismicRichText } from "@prismicio/react";

// Components
import { Gallery } from "../components/images/gallery";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { VideoWithControls } from "../components/videos/video";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  min-height: ${(props) => props.height}px;

  & .column {
    position: relative;
    padding: 110px 0 30px 0;

    height: 100%;

    &.left {
      grid-column: 1;
      width: 50vw;
    }

    &.right {
      grid-column: 2;
      background-color: #d4ddeb;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      width: calc(50vw - ${(props) => props.sbw}px);

      & .content-container,
      & .related-projects-container {
        flex: 100%;
        width: 50%;
      }

      & .text-container {
        position: sticky;
        top: 110px;

        @media (max-height: ${(props) => props.mediaQueryHeight}px) {
          top: calc(
            ${(props) => props.height}px - 1em -
              ${(props) => props.textHeight}px
          );
        }

        /* min-height: calc(${(props) => props.height}px - 110px - 1em); */
        padding: 0 30px;

        margin: 0 0 1em 0;

        & p {
          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        /* & .sticky {
          position: sticky;
          top: 100px;
        } */
      }
    }

    & .single-image,
    & .single-video {
      margin: 2em 0;
    }

    & .single-image-container {
      padding: 0 30px;
      /* margin: 2em 0; */
      /* margin: 0 0 2em 0; */

      & img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
      }
      /* padding: 0 0 30px 0;

      &.padded {
        padding: 0 30px 30px 30px;
      }

      &.image-portrait {
        padding: 90px 120px;
      } */
    }
  }

  & .related-projects-container {
    margin: auto 0 0 0;
    padding: 0 30px 90px 30px;

    & h2 {
      margin: 0 0 20px 0;
    }

    & .related-projects-inner-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;

      & .project-title {
        margin: 10px 0 0 0;

        font-size: 18px;
        line-height: 22px;
      }
    }

    & a {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & h3 {
            font-family: "Selecta Bold", sans-serif;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    grid-template-columns: unset;

    & .column {
      width: 100%;

      &.left {
        /* order: 2; */
        padding: 55px 0 0 0;
        width: 100%;
      }

      &.right {
        /* order: 1; */
        padding: 40px 0 0 0;
        width: 100%;

        & .content-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        & .project-content {
          order: 1;
          width: 100%;

          & .react-slideshow-container {
            & .padded {
              padding: 0;
            }
          }

          &.mobile {
            & .react-slideshow-container {
              & .single-image-container {
                width: 100%;
                height: 100%;
                padding: 0;

                &.image-landscape {
                  /* & img {
                    object-fit: cover;
                  } */
                }

                &.image-portrait {
                  & img {
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }

        & .text-container {
          padding: 0 15px;
          min-height: auto;

          order: 2;

          position: relative;
          top: 0;
        }
      }

      & .single-image-container {
        padding: 0 15px;
        /* padding: 0 0 15px 0;

        &.padded {
          padding: 15px;
          //  0 15px 15px 15px;
        }

        &.image-portrait {
          padding: 35px 50px;
        } */
      }
    }

    & .related-projects-container {
      order: 3;

      padding: 20px 15px 90px 15px;

      & .related-projects-inner-container {
        grid-column-gap: 15px;

        & .project-title {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
`;

const Project = ({ data }) => {
  const windowHeight = use100vh();
  const sbw = useScrollbarWidth();
  const [pageContext, setPageContext] = useContext(PageContext);
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    setPageContext({
      pageTitle: data.prismicProject.data.title.text,
      pageColor: `#D4DDEB`,
      pageType: `project`,
    });
  }, []);

  const SingleImage = ({ content, index }) => (
    <div
      className={`single-image single-image-container image-${ImageOrientation(
        content.primary.image
      )}`}
    >
      <AspectRatioImageContainer image={null} padding={75}>
        <img
          srcSet={content.primary.image.fluid.srcSet}
          src={content.primary.image.fluid.src}
          alt={content.primary.image.alt}
          loading={index <= 2 ? `eager` : `lazy`}
        />
      </AspectRatioImageContainer>
    </div>
  );

  const SingleVideo = ({ content, index }) => (
    <div
      className={`single-video single-video-container ${
        content.primary.full_width ? `full-width` : `padded`
      }`}
    >
      <AspectRatioImageContainer image={null} padding={75}>
        <VideoWithControls content={content} />
      </AspectRatioImageContainer>
    </div>
  );

  const SingleGallery = ({ content, index }) => {
    const galleryContent = content.items.map((item, innerIndex) => (
      <div key={`single_image_slide_${innerIndex}_${index}`}>
        <AspectRatioImageContainer image={null} padding={75}>
          <img
            className={`image-${ImageOrientation(item.image)}`}
            srcSet={item.image.fluid.srcSet}
            src={item.image.fluid.src}
            alt={item.image.alt}
            loading={`lazy`}
          />
        </AspectRatioImageContainer>
      </div>
    ));

    return (
      <div className={`single-gallery-container`}>
        <Gallery
          galleryContent={galleryContent}
          key={`single_image_gallery_${index}`}
          color={`#D4DDEB`}
        />
      </div>
    );
  };

  const leftColumn = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === "image") {
      if (content.primary.image !== null) {
        return (
          <SingleImage
            content={content}
            key={`single_image_left_column_item_${index}`}
            index={index}
          />
        );
      } else {
        return null;
      }
    }

    if (content.slice_type === "video") {
      return (
        <SingleVideo
          content={content}
          key={`single_video_left_column_item_${index}`}
          index={index}
        />
      );
    }

    if (content.slice_type === "gallery") {
      return (
        <SingleGallery
          content={content}
          key={`single_gallery_left_column_item_${index}`}
          index={index}
        />
      );
    }
  });

  const rightColumn = data.prismicProject.data.body1.map((content, index) => {
    if (content.slice_type === "image") {
      return (
        <SingleImage
          content={content}
          key={`single_image_right_column_item_${index}`}
          index={index}
        />
      );
    }

    if (content.slice_type === "video") {
      return (
        <SingleVideo
          content={content}
          key={`single_video_right_column_item_${index}`}
          index={index}
        />
      );
    }

    if (content.slice_type === "gallery") {
      return (
        <SingleGallery
          content={content}
          key={`single_gallery_right_column_item_${index}`}
          index={index}
        />
      );
    }
  });

  // const rightColumnMobile = data.prismicProject.data.body1
  //   .map((content, index) => {
  //     if (content.slice_type === "image") {
  //       return (
  //         <AspectRatioImageContainer
  //           image={null}
  //           padding={75}
  //           key={`single_image_right_column_item_${index}_mobile`}
  //         >
  //           <SingleImage content={content} index={index} />
  //         </AspectRatioImageContainer>
  //       );
  //     }

  //     if (content.slice_type === "video") {
  //       return (
  //         <AspectRatioImageContainer
  //           image={null}
  //           padding={75}
  //           key={`single_video_right_column_item_${index}_mobile`}
  //         >
  //           <SingleVideo content={content} index={index} />
  //         </AspectRatioImageContainer>
  //       );
  //     }

  //     if (content.slice_type === "gallery") {
  //       const galleryContent = content.items.map((item, innerIndex) => (
  //         <div key={`single_image_slide_${innerIndex}_${index}`}>
  //           <AspectRatioImageContainer image={null} padding={75}>
  //             <img
  //               className={`image-${ImageOrientation(item.image)}`}
  //               srcSet={item.image.fluid.srcSet}
  //               src={item.image.fluid.src}
  //               alt={item.image.alt}
  //               loading={`lazy`}
  //             />
  //           </AspectRatioImageContainer>
  //         </div>
  //       ));

  //       return galleryContent;
  //     }
  //   })
  //   .flat();

  const relatedProjects = data.prismicProject.data.related_projects
    .filter((project) => project.related_project.document !== null)
    .map((project, index) => (
      <div className="single-related-project" key={`related_project_${index}`}>
        <Link to={project.related_project.document.url}>
          <img
            srcSet={
              project.related_project.document.data.thumbnail.fluid.srcSet
            }
            src={project.related_project.document.data.thumbnail.fluid.src}
            alt={project.related_project.document.data.thumbnail.alt}
            loading={`lazy`}
          />

          <PrismicRichText
            field={project.related_project.document.data.title.richText}
            components={{
              heading1: ({ children }) => (
                <h3
                  key={`single_related_project_title`}
                  className="project-title"
                >
                  {children}
                </h3>
              ),
            }}
          />
        </Link>
      </div>
    ));

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicProject.data.title.text} - Jamie Whelan Studio`}
        seoText={data.prismicProject.data.text.text}
        seoImage={data.prismicProject.data.thumbnail.url}
      />

      <Page
        height={windowHeight}
        sbw={sbw}
        textHeight={height}
        mediaQueryHeight={height + 110}
      >
        <div className="column left">{leftColumn}</div>

        <div className="column right">
          <div className="content-container">
            {data.prismicProject.data.text.text.length >= 1 && (
              <div className="text-container" ref={ref}>
                {/* <div className="sticky"> */}
                <PrismicRichText
                  field={data.prismicProject.data.text.richText}
                />
                {/* </div> */}
              </div>
            )}

            {/* {isDesktop ? ( */}
            {/* // <> */}
            {rightColumn.length >= 1 && (
              <div className="project-content">{rightColumn}</div>
            )}
            {/* </> */}
            {/* ) : (
              <>
                {rightColumn.length >= 1 && (
                  <div className="project-content mobile">
                    <div className="single-gallery-container">
                      <Gallery
                        galleryContent={rightColumnMobile}
                        color={`#D4DDEB`}
                        type={`project`}
                      />
                    </div>
                  </div>
                )}
              </>
            )} */}
          </div>

          {isDesktop && (
            <>
              {relatedProjects.length >= 1 && (
                <div className="related-projects-container">
                  <h2>Related Projects</h2>

                  <div className="related-projects-inner-container">
                    {relatedProjects}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {!isDesktop && (
          <>
            {relatedProjects.length >= 1 && (
              <div className="related-projects-container">
                <h2>Related Projects</h2>

                <div className="related-projects-inner-container">
                  {relatedProjects}
                </div>
              </div>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default withPrismicPreview(Project);

export const query = graphql`
  query ($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      _previewable
      data {
        title {
          richText
          text
        }
        text {
          richText
          text
        }
        thumbnail {
          alt
          url
        }
        related_projects {
          related_project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    richText
                  }
                  thumbnail {
                    fluid(imgixParams: { ar: "4:3", fit: "crop" }) {
                      srcSet
                      src
                    }
                    alt
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicProjectDataBodyGallery {
            id
            slice_type
            primary {
              full_width
            }
            items {
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  srcSet
                  src
                }
              }
            }
          }
          ... on PrismicProjectDataBodyImage {
            id
            slice_type
            primary {
              full_width
              image {
                alt
                fluid {
                  srcSet
                  src
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicProjectDataBody1Gallery {
            id
            slice_type
            primary {
              full_width
            }
            items {
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  srcSet
                  src
                }
              }
            }
          }
          ... on PrismicProjectDataBody1Image {
            id
            slice_type
            primary {
              full_width
              image {
                alt
                fluid {
                  srcSet
                  src
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

// ... on PrismicProjectDataBodyVideo {
//           id
//           slice_type
//           primary {
//             full_width
//             video {
//               id
//               height
//               width
//               thumbnail_url
//               embed_url
//               html
//             }
//           }
//         }

// ... on PrismicProjectDataBody1Video {
//             id
//             slice_type
//             primary {
//               full_width
//               video {
//                 id
//                 height
//                 width
//                 thumbnail_url
//                 embed_url
//                 html
//               }
//             }
//           }
