import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Context
import { PageContext } from "../context/page-context";
import { AboutTitle } from "../context/about-title";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";
import { Splash } from "../splash/splash";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

const Container = styled.div`
  position: relative;
  width: 100%;

  opacity: ${(props) => props.contentOpacity};
  transition: 250ms opacity ease;

  /* overflow-x: hidden; */
`;

const Layout = ({ children }) => {
  const [aboutTitle, setAboutTitle] = useState(`Profile`);
  const [pageContext, setPageContext] = useState({
    pageTitle: ``,
    pageColor: `#fff`,
    pageType: ``,
  });

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  // Splash
  const [hideSplash, setHideSplash] = useState(false);
  const [textOpacity, setTextOpacity] = useState(1);
  const [containerWidth, setContainerWidth] = useState(100);
  const [splashOpacity, setSplashOpacity] = useState(1);
  const [contentOpacity, setContentOpacity] = useState(0);

  const triggerHideSplash = () => {
    setTextOpacity(0);

    const reduceContainerWidth = setTimeout(() => {
      setContainerWidth(isDesktop ? 50 : 0);

      const fadeOutSplash = setTimeout(() => {
        setSplashOpacity(0);

        const fadeInContent = setTimeout(() => {
          setContentOpacity(1);

          const hideSplash = setTimeout(() => {
            setHideSplash(true);
          }, 500);
          return () => clearTimeout(hideSplash);
        }, 75);
        return () => clearTimeout(fadeInContent);
      }, 350);
      return () => clearTimeout(fadeOutSplash);
    }, 1000);
    return () => clearTimeout(reduceContainerWidth);
  };

  useEffect(() => {
    const closeSplashAutomatically = setTimeout(() => {
      setTextOpacity(0);
      const reduceContainerWidth = setTimeout(() => {
        setContainerWidth(50);

        const fadeOutSplash = setTimeout(() => {
          setSplashOpacity(0);

          const fadeInContent = setTimeout(() => {
            setContentOpacity(1);

            const hideSplash = setTimeout(() => {
              setHideSplash(true);
            }, 500);
            return () => clearTimeout(hideSplash);
          }, 75);
          return () => clearTimeout(fadeInContent);
        }, 350);
        return () => clearTimeout(fadeOutSplash);
      }, 1000);
      return () => clearTimeout(reduceContainerWidth);
    }, 3000);
    return () => clearTimeout(closeSplashAutomatically);
  }, []);

  return (
    <AboutTitle.Provider value={[aboutTitle, setAboutTitle]}>
      <PageContext.Provider value={[pageContext, setPageContext]}>
        <Location>
          {({ location }) => {
            return (
              <>
                <Container
                  location={location.pathname}
                  contentOpacity={contentOpacity}
                >
                  <Normalize />
                  <GlobalStyles />
                  <DefaultSEO />
                  <App
                    location={location}
                    children={children}
                    pageContext={pageContext}
                    setHideSplash={setHideSplash}
                    setContentOpacity={setContentOpacity}
                  />
                </Container>

                {!hideSplash && location.pathname === `/` && (
                  <Splash
                    setHideSplash={setHideSplash}
                    triggerHideSplash={triggerHideSplash}
                    textOpacity={textOpacity}
                    containerWidth={containerWidth}
                    splashOpacity={splashOpacity}
                  />
                )}
              </>
            );
          }}
        </Location>
      </PageContext.Provider>
    </AboutTitle.Provider>
  );
};

export default Layout;
